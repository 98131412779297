.banner,
aside {
  margin-block-start: var(--space-m-xl);
  margin-inline: var(--space-s-4xl);
}

footer > * {
  margin-block-end: var(--space-m-xl);
  margin-inline: var(--space-s-4xl);
}
