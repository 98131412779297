.cluster {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap, var(--space-m));
    justify-items: var(--justify-items, stretch);
    justify-content: var(--justify-content, start);
    align-items: var(--align-items, stretch);
    align-content: var(--align-content, center);
}

.icon {
    width: var(--icon-width, var(--step-0));
    height: var(--icon-height, var(--step-0));
}
.handmade,
.with-icon {
    display: flex;
    flex-wrap: no-wrap;
    align-items: center;

    & a:link,
    a:visited {
        color: currentcolor;
    }
}

.with-icon .icon {
    margin-inline-end: var(--space-2xs);
}

.repel.inline {
    margin-inline-start: auto;
}

.repel.block {
    margin-block-start: auto;
}

.center {
    box-sizing: content-box;
    margin-inline: auto;
    max-inline-size: var(--max-inline-size, 50%);
    text-align: var(--text-align, unset);

    &.intrinsic {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.cover {
    display: flex;
    flex-direction: column;
    min-block-size: 100vh;
    padding: 0;
}

.cover > * {
    margin-block: 1rem;
}

.cover > :first-child:not(svg) {
    margin-block-start: 0;
}

.cover > :last-child:not(svg) {
    margin-block-end: 0;
}

.cover > svg {
    margin-block: var(--space-4xl);
}

.stack {
    display: flex;
    flex-direction: column;
    justify-content: var(--justify-content, start);

    & > * {
        margin-block: 0;
    }

    & > * + * {
        margin-block-start: var(--stack-gap, var(--space-m));
    }

    &.recursive * + * {
        margin-block-start: var(--stack-gap, var(--space-m));
    }
}

.with-sidebar {
    display: flex;
    flex-wrap: wrap;
    gap: var(--sidebar-gap, var(--space-l));
}

/*The location of the sidebar.  Default is sidebar on left*/
.with-sidebar > :first-child,
.with-sidebar.left > :last-child,
.with-sidebar.right > :first-child {
    flex-grow: 1;
    flex-basis: var(--sidebar-max, content);
}

/*Main content*/
.with-sidebar > :last-child,
.with-sidebar.left > :first-child,
.with-sidebar.right > :last-child {
    flex-grow: 999;
    flex-basis: 0;
    min-inline-size: var(--not-sidebar, 50%);
}

.text-block {
    max-inline-size: content;
    & * + * {
        margin-block-start: var(--space-s);
    }
}
