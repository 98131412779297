/*
 * DreamingTree Salon
 * Created with ColorSlurp
 */
:root {
  --white: oklch(99% 0 0);
  --gray-50: oklch(95% 0.01 257.94);
  --gray-400: oklch(60% 0 17.19);
  --gray-500: oklch(50.31% 0 263.56);
  --black: oklch(5% 0 0);
  --blue-100: oklch(90% 0.06 183.32);
  --yellow-100: oklch(90% 0.3 83.43);
  --brown-500: oklch(50% 0.14 41.23);
  --red-500: oklch(50% 0.19 20.67);
  --green-500: oklch(50% 0.18 139.87);
  --blue-500: oklch(50% 0.06 183.32);
  --gray-300: oklch(70% 0.01 257.94);
  --gray-200: oklch(80% 0.01 257.94);
  --gray-100: oklch(90% 0.01 257.94);
}

/* Typography */
:root {
  --handwritten: 'Segoe Print', 'Bradley Hand', Chilanka, TSCu_Comic, casual,
    cursive;
  --antique: Superclarendon, 'Bookman Old Style', 'URW Bookman', 'URW Bookman L',
    'Georgia Pro', Georgia, serif;
  --transitional: Charter, 'Bitstream Charter', 'Sitka Text', Cambria, serif;
  --old-style: 'Iowan Old Style', 'Palatino Linotype', 'URW Palladio L', P052,
    serif;
  --slab-serif: Rockwell, 'Rockwell Nova', 'Roboto Slab', 'DejaVu Serif',
    'Sitka Small', serif;
  --system-ui: system-ui, sans-serif;
  --humanist: Seravek, 'Gill Sans Nova', Ubuntu, Calibri, 'DejaVu Sans',
    source-sans-pro, sans-serif;
  --classical-humanist: Optima, Candara, 'Noto Sans', source-sans-pro,
    sans-serif;
  --geometric-humanist: Avenir, Montserrat, Corbel, 'URW Gothic',
    source-sans-pro, sans-serif;
  --rounded-sans: ui-rounded, 'Hiragino Maru Gothic ProN', Quicksand, Comfortaa,
    Manjari, 'Arial Rounded MT', 'Arial Rounded MT Bold', Calibri,
    source-sans-pro, sans-serif;
  --neo-grotesque: Inter, Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans',
    Arial, sans-serif;
  --industrial: Bahnschrift, 'DIN Alternate', 'Franklin Gothic Medium',
    'Nimbus Sans Narrow', sans-serif-condensed, sans-serif;
  --monospace-code: ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo,
    Consolas, 'DejaVu Sans Mono', monospace;
  --monospace-slab-serif: 'Nimbus Mono PS', 'Courier New', monospace;
}

/* @link https://utopia.fyi/type/calculator?c=280,16,1.2,1962,24,1.25,5,2,&s=0.75|0.5,1.5|2|3|4|6|8,s-l|s-xl|s-2xl|s-3xl|m-xl|m-2xl|m-3xl|l-2xl|l-3xl|xl-3xl|l-4xl|xl-4xl|s-4xl|m-4xl&g=s,xs,3xl,12 */
:root {
  --step--2: clamp(0.6944rem, 0.6502rem + 0.2526vw, 0.96rem);
  --step--1: clamp(0.8333rem, 0.7723rem + 0.3488vw, 1.2rem);
  --step-0: clamp(1rem, 0.9168rem + 0.4756vw, 1.5rem);
  --step-1: clamp(1.2rem, 1.0876rem + 0.6421vw, 1.875rem);
  --step-2: clamp(1.44rem, 1.2896rem + 0.8597vw, 2.3438rem);
  --step-3: clamp(1.728rem, 1.528rem + 1.1431vw, 2.9297rem);
  --step-4: clamp(2.0736rem, 1.8092rem + 1.5111vw, 3.6621rem);
  --step-5: clamp(2.4883rem, 2.1405rem + 1.9875vw, 4.5776rem);
}

/* @link https://utopia.fyi/space/calculator?c=280,16,1.2,1962,24,1.25,5,2,&s=0.75|0.5,1.5|2|3|4|6|8,s-l|s-xl|s-2xl|s-3xl|m-xl|m-2xl|m-3xl|l-2xl|l-3xl|xl-3xl|l-4xl|xl-4xl|s-4xl|m-4xl&g=s,xs,3xl,12 */
:root {
  --space-2xs: clamp(0.5rem, 0.4584rem + 0.2378vw, 0.75rem);
  --space-xs: clamp(0.75rem, 0.6876rem + 0.3567vw, 1.125rem);
  --space-s: clamp(1rem, 0.9168rem + 0.4756vw, 1.5rem);
  --space-m: clamp(1.5rem, 1.3751rem + 0.7134vw, 2.25rem);
  --space-l: clamp(2rem, 1.8335rem + 0.9512vw, 3rem);
  --space-xl: clamp(3rem, 2.7503rem + 1.4269vw, 4.5rem);
  --space-2xl: clamp(4rem, 3.6671rem + 1.9025vw, 6rem);
  --space-3xl: clamp(6rem, 5.5006rem + 2.8537vw, 9rem);
  --space-4xl: clamp(8rem, 7.3341rem + 3.805vw, 12rem);
  /* One-up pairs */
  --space-2xs-xs: clamp(0.5rem, 0.396rem + 0.5945vw, 1.125rem);
  --space-xs-s: clamp(0.75rem, 0.6251rem + 0.7134vw, 1.5rem);
  --space-s-m: clamp(1rem, 0.7919rem + 1.1891vw, 2.25rem);
  --space-m-l: clamp(1.5rem, 1.2503rem + 1.4269vw, 3rem);
  --space-l-xl: clamp(2rem, 1.5838rem + 2.3781vw, 4.5rem);
  --space-xl-2xl: clamp(3rem, 2.5006rem + 2.8537vw, 6rem);
  --space-2xl-3xl: clamp(4rem, 3.1677rem + 4.7562vw, 9rem);
  --space-3xl-4xl: clamp(6rem, 5.0012rem + 5.7075vw, 12rem);
  /* Custom pairs */
  --space-s-l: clamp(1rem, 0.6671rem + 1.9025vw, 3rem);
  --space-s-xl: clamp(1rem, 0.4174rem + 3.3294vw, 4.5rem);
  --space-s-2xl: clamp(1rem, 0.1677rem + 4.7562vw, 6rem);
  --space-s-3xl: clamp(1rem, -0.3317rem + 7.61vw, 9rem);
  --space-m-xl: clamp(1.5rem, 1.0006rem + 2.8537vw, 4.5rem);
  --space-m-2xl: clamp(1.5rem, 0.7509rem + 4.2806vw, 6rem);
  --space-m-3xl: clamp(1.5rem, 0.2515rem + 7.1344vw, 9rem);
  --space-l-2xl: clamp(2rem, 1.3341rem + 3.805vw, 6rem);
  --space-l-3xl: clamp(2rem, 0.8347rem + 6.6587vw, 9rem);
  --space-xl-3xl: clamp(3rem, 2.0012rem + 5.7075vw, 9rem);
  --space-l-4xl: clamp(2rem, 0.3353rem + 9.5125vw, 12rem);
  --space-xl-4xl: clamp(3rem, 1.5018rem + 8.5612vw, 12rem);
  --space-s-4xl: clamp(1rem, -0.8312rem + 10.4637vw, 12rem);
  --space-m-4xl: clamp(1.5rem, -0.2479rem + 9.9881vw, 12rem);
}

/* @link https://utopia.fyi/grid/calculator?c=280,16,1.2,1962,24,1.25,5,2,&s=0.75|0.5,1.5|2|3|4|6|8,s-l|s-xl|s-2xl|s-3xl|m-xl|m-2xl|m-3xl|l-2xl|l-3xl|xl-3xl|l-4xl|xl-4xl|s-4xl|m-4xl&g=s,xs,3xl,12 */
:root {
  --grid-max-width: 122.63rem;
  --grid-gutter: var(--space-s-xs, clamp(1rem, 0.9792rem + 0.1189vw, 1.125rem));
  --grid-columns: 12;
}

.u-container {
  max-width: var(--grid-max-width);
  padding-inline: var(--grid-gutter);
  margin-inline: auto;
}

.u-grid {
  display: grid;
  gap: var(--grid-gutter);
}
